import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import ReceiptGenerator from './ReceiptGenerator';
import LegalPage from './LegalPage';

const Home = () => (
    <div className="container max-w-2xl mx-auto p-12">
        <h1 className="text-2xl font-bold mb-4">領収書発行サービス TEKIKAK Free β</h1>
        <p className="mb-4">
            TEKIKAK Free はフリマなどで領収書の発行を依頼されたときに、サクッとPDF領収書を作ってダウンロードできるサービスです。<br/>
            運営は株式会社hatte が行っており、原則として入力した情報は利用者様のパソコンなどの端末内に保存し、外部への送信はしないように設計されています。
        </p>
        <p>
            サービスの改善を目的として、利用者様の操作状況（領収書発行ボタンの操作や、ヘルプの表示など）をGoogle Analyticsを使用して解析しております。これらは個人を特定しない形で行われておりますので、ご安心ください。
        </p>
        <ReceiptGenerator />
    </div>
);

const App = () => {
    return (
        <Router>
            <AnimatePresence mode="wait">
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/legal" element={<LegalPage />} />
                </Routes>
            </AnimatePresence>
        </Router>
    );
};

export default App;