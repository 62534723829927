import React, { useState, useCallback } from 'react';
import Modal from 'react-modal';
import { HelpCircle } from "lucide-react";

// モーダルのスタイル
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '500px',
        width: '90%'
    }
};

// アプリケーションのルート要素を設定
Modal.setAppElement('#root');

const HelpModal = ({ id, title, content }) => {
    const [isOpen, setIsOpen] = useState(false);

    const openModal = useCallback(() => {
        console.log(`Opening modal: ${id}`);
        setIsOpen(true);
    }, [id]);

    const closeModal = useCallback(() => {
        console.log(`Closing modal: ${id}`);
        setIsOpen(false);
    }, [id]);

    console.log(`Modal ${id} render, isOpen:`, isOpen);

    return (
        <>
            <button
                onClick={openModal}
                className="inline-flex items-center text-blue-500 hover:text-blue-700"
                type="button"
            >
                <HelpCircle className="w-4 h-4 mr-1" />
                <span className="underline">{title}</span>
            </button>
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel={title}
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
            >
                <h2 className="text-xl font-bold mb-4">{title}</h2>
                <div>{content}</div>
                <button
                    onClick={closeModal}
                    className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                    閉じる
                </button>
            </Modal>
        </>
    );
};

export default HelpModal;