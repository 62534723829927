import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const LegalPage = () => {
    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="container mx-auto p-4"
        >
            <h1 className="text-2xl font-bold mb-4">利用規約</h1>
            <div className="prose max-w-none">
                {/* 利用規約の内容をここに記述 */}
                <h2>1. サービスの利用</h2>
                <p>本サービスを利用することにより、ユーザーは以下の条項に同意したものとみなされます。</p>

                <h2>2. 責任の制限</h2>
                <p>当社は、本サービスの利用により生じた損害について、一切の責任を負いません。</p>
                <p>作成した書類の正確性を当社は保証するものではありません。すなわち、作成した書類に起因する損害の責任は、作成した方にあるということです。</p>

                <h2>3. 仕様変更</h2>
                <p>当社は、事前に告知することなく本サービスの仕様を変更することがあります。その際、機能が削減されることがあります。</p>

                <h2>4.個人情報の取扱い</h2>
                <p>当社は、利用者が入力した個人情報や書類の情報についてアクセス解析に関わる情報を除いて、一切取得しません。</p>
                <p>情報を保存する機能を提供していますが、すべて利用者の個別の環境に保存されるように開発しています。<br/>従いまして、個人情報の削除に関する対応をすることはできません。</p>
            </div>
            <Link
                to="/"
                className="mt-4 inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
                戻る
            </Link>
        </motion.div>
    );
};

export default LegalPage;